/**#ddddda gris**/
/**#88b12d verde oscuro**/
/**#8bb02c verde claro**/


.row{
    margin-right: 0;
    margin-left: 0;
}

.col-md-0, .col-md-2, .col-md-4, .col-md-3, .col-md-1, .col-md-5{
    text-align: center;
    -webkit-align-self: center;
            align-self: center;
}

.menu-principal{
    margin-top: 2%;
    display: none;
}

.menu-principal .col-md-10{
    -webkit-align-self: center;
            align-self: center;
}

.menu-principal img{
    width: 36px;
}

.menu-principal h4{
    color: #ddddda;
    font-weight: bold;
    margin-bottom: 0;
}

.menu-principal .primero{
    padding-left: 0;
}

.lateral{
    -webkit-align-self: auto;
            align-self: auto;
}

.menu-lateral img{
    width: 100%;
}

.seccion-loading h4{
    border-bottom: none;
    color: #000000;
    margin-top: 2%;    
}

.lista-config .seccion-loading{
    padding: 10%;
    text-align: center;
}

.card-info-config .seccion-loading{
    padding: 0%;
    text-align: center;
}

.seccion-loading{
    padding-top: 20%;
    text-align: center;
}

.overflow-auto{
    max-width: 100%;
    max-height: 149vh;
}

.overflow-auto.dashboard{
    width: 100%;
}

.izq {
    padding-left: 0;
}

.der {
    padding-right: 0;
}

.overflow-auto.casas{
    height: 135vh;
}

.slider-prop{
    margin: 5% 0 5% 0;
}

.slider-prop p{
    margin-bottom: 0;
}

.slider-prop img{
    margin-left: 3%;
}

.slider-prop .row{
    margin-top: 2%;
}

/**COMPONENTE LOGIN**/
.login{
    width: 50%;
    margin-left: 25%;
    margin-top: 8%;
}

.login .card h4{
    color:#88b12d;
    text-align: center;
    border-bottom: 2px solid #88b12d;
    padding-bottom: 1%;
    margin-bottom: 2%;
    width: 40%;
    padding-right: 5%;
    padding-left: 5%;
    margin-left: 30%;
}

.login .card{
    background: #f7f7f7;
}

.login .card p{
    margin-bottom: 0;
}

.login .card-header{
    border-bottom: none;
    background: #ffffff;
    background: #f7f7f7;
}

.login .card-footer{
    background: #f7f7f7;
    border-top: none;
    padding-top: 0;
    padding-bottom: 0;
}

.login .btn{
    border: 1px solid #88b12d;
    color: #88b12d;
    font-weight: bold;
}

.login .btn:hover{
    background: #8bb02c;
    color: #ffffff;
}

.login .col-sm-8{
    -webkit-align-self: center;
            align-self: center;
}

.login .col-sm-4{
    text-align: end;
}

.login label{
    color: #8bb02c;
}

/**COMPONENTE ADMIN APP**/
.adminapp .board .col-sm-2,
.adminapp .board .col-sm-10{
    padding-right: 0;
    padding-left: 0;
}

.adminapp .board .lateral{
    background: #ddddda;
}

.adminapp .board .configuracion{
    color: #ffffff;;
    padding-top: 1rem;
    text-align: center;
    margin-bottom: 0;
}

.adminapp .board a:hover{
    text-decoration: none;
}

.adminapp .col-sm-2 .lateral{
    height: 100vh;
}

.adminapp .board .configuracion{
    background: #ddddda;
}

.adminapp .menu-lateral .col-sm-2{
    background: #ddddda;
}

.adminapp .board .lateral{
    height: 160vh;
}

/**COMPONENTE MENU LATERAL**/
.menu-lateral{
    height: auto;
}

.menu-lateral .card-header{
    background-color: #dad7c6;
}

.menu-lateral .btn{
    color: #ffffff;
    font-weight: 500;
    padding-left: 0%;
    text-decoration: none;
}

.menu-lateral .btn:hover{
    text-decoration: none;
    font-weight: bold;
}

.menu-lateral li{
    padding-left: 15%;
    color: #ffffff;
}

.menu-lateral li:hover{
    font-weight: 500;
    cursor: default;
}

.menu-lateral .card{
    border: none;
}

/**COMPONENTE DASHBOARD INVENTARIO**/
.dashboard,
.menu-inventario{
    padding: 2% 0 2% 2%;
}

.dashboard .card,
.menu-inventario .card{
    cursor: pointer;
}

.dashboard img,
.menu-inventario  img{
    height: 15vh;
}

.dashboard .card,
.menu-inventario .card{
    width: 23%;
    text-align: center;
    margin: 0 5% 2% 5%;
}

.dashboard .card-header,
.menu-inventario .card-header{
    background: #ffffff;
    padding: 0;
    border-bottom: none;
}

.dashboard .card-header h5,
.menu-inventario .card-header h5{
    color: #88b12d;
    margin: 0 2% 0 2%;
}

.dashboard .blanco,
.menu-inventario .blanco{
    border: none;
    cursor: default;
}

.dashboard .blanco{
    width: 12.5%;
}

.dashboard .card-body,
.menu-inventario .card-body{
    padding: 0.25rem;
}

.dashboard .card-body p,
.menu-inventario .card-body p{
    font-weight: 500;
    font-size: 0.75rem;
    padding: 2% 6% 2% 6%;
}

.dashboard h4,
.menu-inventario h4{
    color: #88b12d;
    margin-bottom: 0;
    padding-bottom: 2%;;
}

.dashboard .col-sm-3,
.menu-inventario .col-sm-3{
    text-align: center;
}

.dashboard .card img,
.menu-inventario .card img{
    width: 64px;
    height: 64px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

/**COMPONENTE PRODUCTOS, CATEGORIAS Y SUB CATEGORIAS**/
.config{
    padding: 2%;
}

.config .lista-config h4{
    padding-bottom: 1%;
}

.config .nav{
    margin-bottom: 1%;
}

.config .nav li{
    width: 50%;
    text-align: center;
    color: #88b12d;
    cursor: pointer;
}

.config .nav .active{
    background: #f7f7f7;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    font-weight: bold;
}

.config .lista-config .filtro{
    padding: 1%;
}

.config .lista-config h4{
    color: #88b12d;
    margin-bottom: 0;
}

.config .nuevo-config .primero,
.config .lista-config .primero,
.config .botones .primero{
    padding-left: 0;
    padding-right: 15px;
    text-align: start;
}

.config .nuevo-config .foto{
    width: 100%;
}

.config .nuevo-config .segundo,
.config .lista-config .segundo,
.config .botones .segundo{
    padding-left: 15px;
    padding-right: 0;
    text-align: end;
}

.config .botones .btn{
    border: 1px solid #88b12d;
    color: #88b12d;
}

.config .botones .btn:hover{
    background: #8bb02c;
    color: #ffffff;
}

.config .lista-config .titulo p{
    color: #88b12d;
    margin-bottom: 0;
}

.config .lista-config p{
    margin-bottom: 0;
}

.config .lista-config .imagen-producto{
    width: 100%;
    text-align: center;
    margin-bottom: 2%;
}

.config .lista-config .imagen-producto .card-img-top{
    height: 50vh;
    width: 50%;
}

.config .lista-config .imagen-producto img{
    text-align: center;
    width: 36px;
}

.config .lista-config .row .edit-camera{
    width: 30px;
}

.config .lista-config .lista .row{
    margin-top: 1%;
}

.config .modal h5{
    font-weight: bold;
}

.config .modal h5,
.config .modal h6{
    color: #88b12d;
}

.config .modal li{
    border: none;
    padding: 0;
    margin-bottom: 1%;
    margin-top: 1%;
}

.config .modal .card-footer .segundo{
    text-align: end;
}

.config .modal .btn{
    border: 1px solid #88b12d;
    color: #88b12d;
}

.config .modal strong{
    color: #88b12d;
}

.config .form-config h4{
    color: #88b12d;
}

.config .form-config label{
    color: #8bb02c;
}

.config .form-config .form-group{
    margin-bottom: 0;
}

.config .form-config .form-group.check img{
    width: 24px;
}

.config .form-config .row.input{
    margin-bottom: 1%;
}

.config .form-config .imagenes{
    margin-top: 2%;
    text-align: center;
}

.config .form-config .boton-submit .btn{
    border: 1px solid #88b12d;
    color: #88b12d;
    font-weight: bold;
    width: 25%;
    margin-left: 75%;
}

.config .form-config .boton-submit .btn:hover{
    background: #88b12d;
    color: #ffffff;
}

.config .form-config .imagenes .imagen{
    width: 25%;
}

.config .form-config .imagenes .img{
    width: 100%;
}

.config .form-config .imagenes .borrar-img{
    width: 24px;
}

.card-info-config .card-body p{
    text-align: start;
}

.lista-config .card-info-config .card .card-body {
    padding: 2%;
}

.lista-config .card-info-config .card-footer .cerrar,
.lista-config .card-edit-config .card-footer .cerrar{
    width: 50%;
    margin-left: 50%;
    font-weight: bold;
}

.lista-config .card-info-config .card-footer .editar,
.lista-config .card-edit-config .card-footer .editar{
    width: 70%;
    margin-left: 30%;
    font-weight: bold;
}

.lista-config .card-info-config .card-footer .btn:hover,
.lista-config .card-edit-config .card-footer .btn:hover{
    background: #88b12d;
    color: #ffffff;
}

.lista-config .card-info-config .card-body .row{
    margin-bottom: 1%;
}

.lista-config .card-edit-config .card-body {
    padding: 2%;
}


.lista-config .card-edit-config .card-body .check img{
    width: 24px;
}

.lista-config .card-edit-config .card-body label{
    font-size: 12px;
}

.lista-config .card-edit-config .imagen .borrar-img{
    width: 24px;
}

.lista-config .card-edit-config .imagen .img{
    width: 100%;
}

.lista-config .card-edit-config .imagen{
    width: 33%;
    text-align: center;
}

.config .nuevo-config .imagen .img{
    width: 100%;
}

.config .nuevo-config .imagen .borrar-img{
    width: 24px;
}

.config .nuevo-config .guardar {
    border: 1px solid #88b12d;
    color: #88b12d;
    font-weight: bold;
}

.config .nuevo-config .guardar:hover {
    background: #88b12d;
    color: #ffffff;
}

.config .lista-config .card-info-config .col-sm-6 .img{
    width: 100%;
}

.config .lista-config .card-info-config .col-sm-6 .borrar-img{
    width: 24px;
}

.form-config .nuevo-config img{
    width: 24px;
    height: 24px;
}

.form-config .nuevo-config .row.input{
    -webkit-align-items: center;
            align-items: center;
    text-align: center;
}

.form-config .nuevo-config .der.label{
    text-align: center;
}

/**COMPONENTE ADMIN**/
.btn.editaradmin,
.admincancelar,
.adminguardar{
    width: 25%;
    border: 1px solid #88b12d;
    color: #88b12d;
    margin-left: 75%;
    margin-top: 5%;
    font-weight: 500;
}

.editaradmin:hover,
.admincancelar:hover,
.adminguardar:hover{
    background: #88b12d;
    color: #ffffff;
}

.admincancelar,
.adminguardar{
    width: 25%;
    margin-left: 25%;
}

.card-edit-config.editaradmin .row{
    -webkit-align-items: center;
            align-items: center;
}

.card-edit-config.editaradmin img{
    width: 24px;
    height: 24px;
}

/**COMPONENTE USUARIOS**/
.usuarios h4,
.compras h4{
    color: #88b12d;
}

.usuarios .col-sm-1, 
.usuarios .col-sm-3,
.usuarios .col-sm-2,
.compras .col-sm-1,
.compras .col-sm-3,
.compras .col-sm-2{
    text-align: center;
    background: #ffffff;
}

.usuarios p,
.compras p{
    margin-bottom: 0;
}

.usuarios .usuario h4,
.compras .compra h4{
    color: #88b12d;
}

/**COMPONENTE SLIDER**/
.form-config.sliders .col-sm-8,
.form-config.sliders .col-sm-4{
    -webkit-align-self: center;
            align-self: center;
}

.form-config.sliders h6{
    margin-bottom: 0;
}

.form-config.sliders .sliders-previo{
    margin-top: 2%;
}

.form-config.sliders .carousel-item h4{
    color: #ffffff;
}

.form-config.sliders .carousel-item,
.lista-config .carousel-item{
    height: 50vh;
    background: #88b12d;
    border-radius: 10px;
}

.form-config.sliders .carousel-item h4{
    padding: 13.5%;
}

.form-config.sliders .carousel-item .card,
.lista-config .carousel-item .card{
    border-radius: 10px;
    background: transparent;
}

.form-config.sliders .carousel-item .row,
.lista-config .carousel-item .row{
    height: 100%;
}

.form-config.sliders .carousel-item .col-sm-6,
.form-config.sliders .carousel-item .col-sm-4,
.lista-config .carousel-item .col-sm-6,
.lista-config .carousel-item .col-sm-4{
    -webkit-align-self: center;
            align-self: center;
}

.form-config.sliders .carousel-item p,
.lista-config .carousel-item p{
    color: #ffffff;
}

.lista-config .carousel-item .card-title{
    font-size: 1.25rem;
}

.lista-config .carousel-item .card-text{
    font-size: 1.50rem;
}

.lista-config .card-footer{
    background: #ffffff;
    margin-bottom: 4%;
    border-radius: 5px;
    opacity: 0.75;
}

.form-config.sliders .card-footer{
    background: #ffffff;
    margin-bottom: 4%;
    border-radius: 5px;
}

.form-config.sliders .card-footer .card-text,
.lista-config .card-footer .card-text{
    color: #88b12d;
    margin-bottom: 0;
}

.form-config.sliders .card-footer .card-title,
.lista-config .card-footer .card-title{
    color: #9e1919;
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 0;
}

.form-config.sliders .card .btn.siguiente,
.lista-config .card .btn.siguiente{
    width: 50%;
    margin-left: 23%;
    font-weight: bold;
}

.form-config.sliders .card .btn.anuncio,
.form-config.sliders .card .btn.siguiente{
    border: 1px solid #88b12d;
    border-radius: 5px;
    color: #88b12d;
    margin-top: 2%;
    margin-bottom: 2%;
}

.form-config.sliders .card .btn.anuncio:hover,
.form-config.sliders .card .btn.siguiente:hover{
    background: #88b12d;
    color: #ffffff;
}

.form-config.sliders .card .col-sm-6,
.lista-config .card .col-sm-6{
    -webkit-align-self: center;
            align-self: center;
}

.lista-config .card .imagen-producto{
    -webkit-align-self: auto;
            align-self: auto;
}

.form-config.sliders .card .col-sm-6 img{
    width: 20%;
    margin-left: 40%;
}

.form-config.sliders .card .col-sm-6{
    -webkit-align-self: center;
            align-self: center;
}

.form-config.sliders .card .nuevo-anuncio .imagen{
    margin-left: 32%;
    margin-top: 2%;
    border: 1px solid #88b12d;
    color: #88b12d;
    font-weight: bold;
}

.form-config.sliders .card .nuevo-anuncio .imagen:hover{
    background: #88b12d;
    color: #ffffff;
} 

/**COMPONENTE COMPRAS**/
.config.usuario .accordion button{
    width: 100%;
}

.config.usuario .accordion .card-body{
    padding: 0.25rem;
}

/**COMPONENTE PRODUCTOS**/
.accordion.productos .card-header,
.accordion.subcategorias .card-header,
.accordion.historial .card-header{
    padding: 0.5rem 0.25rem 0.5rem 0.25rem;
}

.accordion.productos.categorias .card-header{
    background: #e0e0e0

}

.accordion.productos.subcategorias .card-header{
    padding: 0.5rem 1.50rem 0.5rem 1.50rem;
    background: #f7f7f7
}

.accordion.productos .card-body .titulo,
.accordion.productos.subcategorias .card-body .titulo,
.accordion.subcategorias .card-body .titulo,
.accordion.historial .card-body .titulo{
    margin-bottom: 1%;
}

.accordion.productos .card-header .col-sm-8,
.accordion.productos.subcategorias .card-header .col-sm-8,
.accordion.subcategorias .card-header .col-sm-8,
.accordion.historial .card-header .col-sm-8{
    font-size: 1.25rem;
    -webkit-align-self: center;
            align-self: center;
}

.accordion.productos .seccion-loading,
.accordion.productos.subcategorias .seccion-loading,
.accordion.subcategorias .seccion-loading,
.accordion.historial .seccion-loading{
    padding: 15%;
}

.form-config .nuevo-config .col-sm-4 {
    text-align: center;
}

.form-config .nuevo-config .col-sm-4 img{
    width: 25%;
    margin: 10%;
}

.form-config .nuevo-config .col-sm-4 button{
    border-radius: 5px;
    border: 1px solid #88b12d;
    color: #88b12d;
}

/**COMPONENTE CATEGORIAS**/
.lista-config.categorias .card-config .lista .col-sm-3{
    padding-left: 11%;
}

.lista-config.categorias .card-info-config img{
    width: 25%;
    margin-left: 36.75%;
}

.lista-config.categorias .card-edit-config .card-img-top{
    width: 25%;
    height: 25%;
}

.lista-config.categorias .card-edit-config .edit-camera{
    cursor: pointer;
    margin-left: 2%;
}

.form-config .col-sm-8{
    -webkit-align-self: center;
            align-self: center;
}

.lista-config.categorias .card-info-config .seccion-loading{
    padding: 15%;
}

/**COMPONENTE HISTORIAL PRODUCTOS**/
.historial .accordion.historial{
    padding: 2%;
}

.historial .accordion.historial .card-header img,
.historial .accordion.historial .card-body img{
    width: 36px;
}

.historial .accordion.historial .card-body {
    text-align: center;
}

.lista-config .historial-usuarios  .card-footer .btn{
    border: 1px solid #88b12d;
    color: #88b12d;
    font-weight: bold;
    width: 20%;
    margin-left: 80%;
}

.lista-config .historial-usuarios  .card-footer .btn:hover{
    background: #88b12d;
    color: #ffffff;
}

.lista-config .historial-usuarios .card-body{
    text-align: center;
}

.lista-config .historial-usuarios .seccion-loading{
    padding: 15%;
}

/**COMPONENTE HISTORIAL GANANCIAS**/
.historial .accordion.ganancias{
    padding: 2%;
}

/**COMPONENTE ALMACEN**/
.config.almacen .card-info-config .seccion-loading{
    padding: 15%;
}

/**COMPONENTE COMPRAS VENTAS**/
.accordion.compras h2 strong,
.accordion.ventas h2 strong{
    font-size: 1.25rem;
}

.accordion.compras h2,
.accordion.ventas h2 {
    font-size: 1.25rem;
}

.accordion.compras .col-sm-1,
.accordion.compras .col-sm-2,
.accordion.compras .col-sm-3,
.accordion.ventas .col-sm-1,
.accordion.ventas .col-sm-2,
.accordion.ventas .col-sm-3{
    background: none;
}

.accordion.compras .titulo strong,
.accordion.ventas .titulo strong{
    color: #88b12d;
}

.accordion.compras .card-header,
.accordion.ventas .card-header{
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}

.accordion.compras .card-body,
.accordion.ventas .card-body{
    padding: 0.25rem 0.75rem 0.25rem 0.75rem;
    font-size: 1.1rem;
}

.form-config.nueva-compra .col-sm-8,
.form-config.nueva-compra .col-sm-8{
    -webkit-align-self: initial;
            align-self: initial;
}

.form-config.nueva-compra .col-sm-4 li:hover{
    background: #88b12d;
    color: #ffffff;
    padding: 1%;
    border-radius: 5px;
    margin-right: 1%;
    cursor: pointer;
}

.form-config.nueva-compra .col-sm-4 .input-group .form-control{
    padding: 6%;
}

.form-config.nueva-compra .input-group{
    margin-bottom: 2%;
}

.form-config.nueva-compra img{
    width: 24px;
}

.form-config.nueva-compra .texto-izquierda{
    text-align: start;
}

.form-config.nueva-compra .limpiar{
    width: 60%;
    margin-left: 40%;
}

.form-config.nueva-compra .limpiar:hover{
    background: #8bb02c;
    color: #ffffff;
}

.form-config.nueva-venta .list-group .col-sm-2{
    -webkit-align-self: center;
            align-self: center;
}

.form-config.nueva-venta .list-group .col-sm-2 img{
    width: 24px;
    cursor: pointer;
}


.form-config.nueva-venta .list-group .col-sm-2 img:hover{
    width: 30px;
}

/**COMPONENT IMPRIMIR EXCELL**/
.imprimir{
    padding: 2%;
}

/**COMPONENTE COMPRAS**/
.config.compras-usuarios .lista-config h4{
    padding-bottom: 0;
}

.compras-usuarios .col-sm-8,
.compras-usuarios .col-sm-4{
    -webkit-align-self: center;
            align-self: center;
}

.compras-usuarios img{
    cursor: pointer;
}

.accordion.compras .usuarios{
    padding: 0;
}

.accordion.compras .usuarios h4{
    font-size: 1rem;
}

.accordion.compras .usuarios .datos{
    color: #000000;
}

.accordion.compras .card-header{
    background: #e0e0e0;
}

.accordion.usuarios .card-header{
    background: #f7f7f7;
}

.accordion.usuarios .card-header .datos .metodo{
    cursor: pointer;
}

.accordion.usuarios .modalvoucher .modal-footer .btn{
    background: #ffffff;
}

.accordion.usuarios .modalvoucher .modal-footer .btn:hover{
    background: #88b12d;
    color: #ffffff;
}

.accordion.compras .card-header .row strong,
.accordion.compras .card-body .row strong{
    color: #88b12d;
}

.dashboard.productos .card.primera,
.dashboard.productos .card.segunda{
    width: 20%;
}

.dashboard.productos .card.blanco.primera,
.dashboard.productos .segunda .card.blanco.segunda{
    width: 20%;
}

/**COMPONENTE LISTA PRODUCTOS**/
.lista-config .card-info-config.ver .seccion-loading{
    padding: 15%;
}

.lista-config .modal .card-info-config .btn.extra{
    display: none;
}

.lista-config .modal.editar .overflow-auto{
    height: 90vh;
}

.lista-config .modal .card-img-top{
    width:50%;
    margin-left: 25%;
}

.lista-config .modal .imagen-producto{
    margin-left: 0;
}

.lista-config .modal .imagen-producto .card-img-top{
    width: 35%;
    margin-left: 0;
    height: 25vh;
}

.menu-inventario .card.primera{
    width: 25%;
    margin-left: 2.5%;
}

.menu-inventario .card.primera.blanco{
    width: 3%;
}

.menu-inventario .card.segunda{
    width: 25%;
    margin-left: 2.5%;
}

.menu-inventario .card.segunda.blanco{
    width: 3%;
}

.lista-config .card-body{
    padding: 0;
}

.nueva-compra .col-md-4 li{
    padding: 0;
}

.nueva-compra .col-md-4 p,
.nueva-compra .col-sm-4 p,
.nueva-compra .col-xl-4 p,
.nueva-compra .col-lg-4 p{
    cursor: pointer;
    margin-bottom: 0;
    padding: 1%;
    text-align: left;
}

.form-config.nueva-compra .limpiar{
    border: 1px solid #88b12d;
    color: #88b12d;
}

.nueva-venta .col-lg-4{
    -webkit-align-self: auto;
            align-self: auto;
    text-align: left;
}

.compras-usuarios .lista-config .card-header .col-md-8{
    -webkit-align-self: center;
            align-self: center;
}

.accordion.historial .card-header .col-md-8{
    -webkit-align-self: center;
            align-self: center;
}

.accordion.historial .card-header h2{
    color: #88b12d;
    font-size: 1.50rem;
}

.config.almacen .imprimir .btn{
    border: 1px solid #88b12d;
    color: #88b12d;
    font-weight: bold;
    width: 50%;
    margin-left: 50%;
}

.config.almacen .imprimir .btn:hover{
    background: #8bb02c;
    color: #ffffff;
}

/**COMPONENTE FINANZAS**/
.config.finanzas .nav-item{
    width: 33%;
}

/**NUEVO PRODUCTO**/
.caractproductos .config .medida{
    width: 50%;
    float: right;
    padding-left: 1%;
}

.caractproductos .config .numero{
    width: 50%;
    float: left;
    padding-right: 1%;
}

.caractproductos .nuevo-config .label{
    text-align: left;
}

.lista-config .accordion img{
    width: 30px;
}

.lista-config .card-config img{
    width: 30px;
}

.lista-config .modal .imagen-producto .card-img-top{
    width: 30%;
    height: auto;
}

.lista-config .imagen-producto .row img{
    width: 33%;
}

.lista-config .card .col-sm-6{
    -webkit-align-self: auto;
            align-self: auto;
}

.caractproductos .lista-config .card .detalles-producto{
    text-align: start;
}

.caractproductos .lista-config .card .detalles-producto .segundo{
    text-align: start;
}

.caractproductos .lista-config .card-footer {
    padding: 0.5rem;
    margin-bottom: 0;
}

.caractproductos .lista-config .card-body{
    padding: 2%;
}
.caractproductos .lista-config .card-body .row .row img{
    width: 50%;
}

.caractproductos .lista-config .card-body .row .row .borrar-imagen{
    width: 48px;
}

.caractproductos .card-info-config .card-header .segundo{
    -webkit-align-self: center;
            align-self: center;
    text-align: center;
}

.caractproductos .card-info-config .card-header .segundo label{
    color: #88b12d;
    font-weight: bold;
}

/**COMPONENTE ACTUALIZAR PRODUCTO**/
.actualizar-productos .col-sm-2 {
    -webkit-align-self: center;
            align-self: center;
}

.actualizar-productos .form-group{
    margin-bottom: 0;
}

.actualizar-productos .btn{
    border: 1px solid #88b12d;
    color: #88b12d;
    font-weight: bold;
}

.actualizar-productos .btn:hover{
    background: #88b12d;
    color: #ffffff;
}

.actualizar-productos label{
    color: #88b12d;
    font-size: 0.9rem;
    font-weight: bold;
    text-align: start;
}

/**COMPONENTE FOOTER**/
.board-developer{
    padding: 30px 30.5px 30px 30.5px;
    background: #272727;
}

.board-developer p{
    color: #bbbbbb;
    margin-bottom: 0;
    font-size: 13px;
}

/**MEDIA SCREENS**/
@media (max-width: 1199px){ /**992px -1199px**/
    .login .card h4{
        width: 60%;
        margin-left: 20%;
    }

    .dashboard .card,
    .menu-inventario .card{
        width: 25%;
        margin: 0 3% 2% 3%;
    }

    .lateral{
        display: none;
    }

    .caractproductos .lista-config .accordion.subcategorias .card-body .col-lg-0 p,
    .caractproductos .lista-config .accordion.subcategorias .card-body .col-lg-0 img{
        display: none;
    }

    .caractproductos .config{
        padding: 1%;
    }

    .lista-config .modal .card-info-config .btn.extra{
        display: block;
    }

    .menu-inventario .card.primera{
        width: 25%;
    }

    .menu-inventario .card.primera.blanco{
        width: 3%;
    }

    .menu-inventario .card.segunda{
        width: 25%;
    }

    .menu-inventario .card.segunda.blanco{
        width: 3%;
    }

    .nueva-compra .col-md-4 h4{
        font-size: 1.25rem;
    }

    .nueva-compra .col-md-4 h5{
        font-size: 1.20rem;
    }

    .nueva-compra .col-md-4 p{
        font-size: 0.9rem;
        cursor: pointer;
    }

    .nueva-compra .col-md-4 {
        text-align: left;
    }

    .nueva-venta .nuevo-config .col-lg-0{
        display: none;
    }

    .menu-principal{
        display: block;
        cursor: pointer;
    }
}


@media (max-width: 991px){/**768 -991px**/
    .login .card h4{
        width: 60%;
        margin-left: 20%;
    }

    .dashboard .card,
    .menu-inventario .card{
        width: 30%;
        margin: 0 1% 2% 1%;
    }

    .dashboard .card.blanco{
        width: 0;
    }

    .overflow-auto.dashboard{
        height: 100vh;
    }

    
    .dashboard.productos .card.primera{
        width: 33%;
        margin:0;
    }
    
    .dashboard.productos .card.segunda{
        width: 30%;
        margin: 0;
    }

    .dashboard.productos .card.blanco.segunda{
        width: 3%;
    }

    .dashboard.productos .card.blanco.primera{
        width: 16.5%;
    }
    
    .caractproductos .lista-config .accordion.subcategorias .card-body p{
        font-size: 0.9rem;
    }

    .menu-inventario .card.primera{
        width: 25%;
    }

    .menu-inventario .card.primera.blanco{
        width: 3%;
    }

    .menu-inventario .card.segunda{
        width: 25%;
    }

    .menu-inventario .card.segunda.blanco{
        width: 3%;
    }

    .config.almacen .card .col-md-0 p{
        display: none;
    }

    .accordion.compras .col-md-0{
        display: none;
    }

    .accordion.compras .col-md-0 p{
        display: none;
    }

    .nueva-compra .col-md-0 .nuevo-config{
        display: none;
    }

    .lista-config .ventas .card .col-md-0 p{
        display: none;
    }
    
    .nueva-venta .col-md-0{
        display: none;
    }

    .nueva-venta .nuevo-config .col-sm-0{
        display: none;
    }

    .config .usuario .row p{
        font-size: 0.9rem;
    }

    .compras-usuarios .usuario-productos .col-md-0,
    .compras-usuarios .usuario-productos .col-md-0 p{
        display: none;
    }

    .lista-config .modal.historial-usuarios .card-body .col-md-0,
    .lista-config .modal.historial-usuarios .card-body .col-md-0 p{
        display: none;
    }
}


@media (max-width: 767px){ /**576px - 767px**/
    .login{
        width: 80%;
        margin-left: 10%;
    }

    .dashboard .card,
    .menu-inventario .card{
        width: 40%;
        margin: 0% 3% 2% 3%;
    }

    .dashboard.productos .card.blanco.primera,
    .dashboard.productos .card.blanco.segunda{
        display: none;
    }

    
    .dashboard.productos .card.primera,
    .dashboard.productos .card.segunda{
        width: 45%;
        margin: 2%;
    }

    .caractproductos .accordion.productos .card-header .col-sm-8{
        font-size: 1rem;
    }
    
    .caractproductos .lista-config .accordion.subcategorias .card-body img{
        width: 24px;
    }

    .lista-config.categorias .col-sm-0 p,
    .lista-config.categorias .col-sm-0 img{
        display: none;
    }

    .lista-config .subcategorias .card-body .col-sm-0 img,
    .lista-config .subcategorias .card-body .col-sm-0 p,
    .lista-config .subcategorias .card-body .col-0{
        display: none;
    }

    .menu-inventario .card.primera{
        width: 40%;
    }

    .menu-inventario .card.primera.blanco{
        width: 3%;
    }

    .menu-inventario .card.segunda{
        width: 40%;
    }

    .menu-inventario .card.segunda.blanco{
        width: 3%;
    }

    .accordion.compras .row strong{
        font-size: 1rem;
    }
    .accordion.compras .row p{
        font-size: 1rem;
    }

    .form-config.nueva-compra h4{
        font-size: 1.10rem;
    }

    .form-config.nueva-compra .botones button{
        width: 100%;
        margin-top: 2%;
    }

    .form-config.nueva-compra .primero{
        padding-left: 0;
        padding-right: 0;
    }

    .form-config.nueva-compra .segundo{
        padding-left: 0;
        padding-right: 0;
    }

    .lista-config .ventas .card p,
    .lista-config .ventas .card strong{
        font-size: 1rem;
    }

    .lista-config .ventas .card-body p,
    .lista-config .ventas .card-body strong{
        font-size: 0.9rem;
    } 

    .nueva-venta .nuevo-config .primero {
        padding-left: 0;
        padding-right: 0;
    }

    .nueva-venta .nuevo-config .segundo {
        padding-right: 0;
        padding-left: 0;
    }

    .nueva-venta .nuevo-config button{
        width: 100%;
        margin-top: 5%;
    }

    .config .usuario .col-sm-0{
        display: none
    }

    .compras-usuarios .usuario .col-sm-0 p{
        display: none;
    }

    .compras-usuarios .usuario-producto .col-sm-0 {
        display: none;
    }

    .compras-usuarios .usuario-productos .col-sm-0,
    .compras-usuarios .usuario-productos .col-sm-0 p{
        display: none;
    }

    .accordion.historial .col-sm-0,
    .accordion.historial .col-sm-0 p{
        display: none;
    }

    .lista-config.proveedores .col-sm-0,
    .lista-config.proveedores .col-sm-0 p{
        display: none;
    }

    .config .lista-config.proveedores .titulo p{
        font-size: 0.9rem;
    }
}

@media (max-width: 575px) { /**0 - 575px**/
    .login .btn{
        width: 100%;
        margin-top: 5%;
    }

    .dashboard .card{
        width: 50%;
        margin: 0 23% 2% 23%;
    }

    .menu-inventario .card{
        width: 54%;
        margin: 0 21% 2% 21%;
    }

    .dashboard .card.blanco{
        width: 0;
    }

    .overflow-auto.dashboard{
        height: 140vh;
    }

    
    .dashboard.productos .card.primera,
    .dashboard.productos .card.segunda{
        width: 60%;
        margin: 10%;
    }
    
    .caractproductos .lista-config .accordion.subcategorias .card-body .titulo p{
        font-size: 0.75rem;
    }
    
    .caractproductos .lista-config .accordion.subcategorias .card-body .titulo .proveedor{
        display: none;
    }

    .lista-config .modal .card-info-config .card-footer .btn{
        width: 100%;
        margin-top: 2%;
    }

    .lista-config .modal .card-info-config .card-footer .segundo{
        padding-left: 0;
        padding-right: 15px;
    }

    .lista-config .modal.editar .card-info-config .card-body .segundo{
        padding-left: 0;
    }

    .lista-config .modal.editar{
        height: 100vh;
    }

    .config .nuevo-config .primero,
    .config .nuevo-config .segundo{
        padding-left: 0;
        padding-right: 0;
    }

    .form-config .nuevo-config .col-sm-4 button{
        margin-bottom: 5%;
    }

    .config .categorias .modal .btn{
        width: 100%;
        margin-top: 5%;
    }
    
    .config .categorias .modal .card-footer .col-12{
        padding-left: 0;
        padding-right: 0;
    }

    .lista-config .subcategorias .card-header .col-4 img{
        width: 24px;
    }

    .lista-config .subcategorias .card-header .col-8 {
        font-size: 1rem;
    }

    .lista-config .subcategorias .editar .btn{
        width: 100%;
    }

    .lista-config .subcategorias .editar .segundo,
    .lista-config .subcategorias .editar .card-footer .segundo{
        padding-left: 0;
        padding-right: 0;
    }

    .lista-config .subcategorias .editar .primero,
    .lista-config .subcategorias .editar .card-footer .primero{
        padding-right: 0;
        padding-left: 0;
    }

    .lista-config .modal .card-info-config .card-footer .segundo{
        padding-left: 0;
        padding-right: 0;
    }
    
    .lista-config .unidades .primero{
        padding-left: 0;
        padding-right: 0;
    }

    .menu-inventario .card.primera{
        width: 60%;
        margin-left: 20%;
    }

    .menu-inventario .card.primera.blanco{
        width: 5%;
    }

    .menu-inventario .card.segunda{
        width: 60%;
        margin-left: 20%;
    }

    .menu-inventario .card.segunda.blanco{
        width: 5%;
    }

    .config.almacen .titulo p{
        font-size: 0.72rem;
        margin-top: 5%;
    }

    .accordion.compras .titulo p{
        font-size: 0.9rem;
    }

    .accordion.compras .col-2 img{
        width: 24px;
    }

    .accordion.compras .col-0 p{
        display: none;
    }

    .form-config.nueva-compra .limpiar{
        width: 100%;
        margin-left: 0;
    }

    .form-config.nueva-compra .col-12{
        padding-left: 0;
        padding-right: 0;
    } 

    .lista-config .ventas .card p,
    .lista-config .ventas .card strong{
        font-size: 0.9rem;
    }

    .lista-config .ventas .card-body .col-0 p,
    .lista-config .ventas .card-body .col-0{
        display: none;
    }
    
    .nueva-venta .nuevo-config .productos-venta p{
        font-size: 0.9rem;
    }

    .config .usuario .col-0{
        display: none
    }

    .compras-usuarios .lista-config .card-header .col-md-8{
        font-size: 1.2rem;
    }

    .compras-usuarios .card-body.productos .col-md-0,
    .compras-usuarios .card-body.productos .col-md-0 p{
        display: none;
    }

    .accordion.historial .card-header h2{
        font-size: 1.20rem;
    }

    .accordion.historial .card-body.usuarios .titulo{
        font-size: 0.9rem;
    }

    .accordion.historial .card-body.usuarios .col-md-1 img{
        width: 30px;
    }
    .accordion.historial .card-body.usuarios .col-0,
    .accordion.historial .card-body.usuarios .col-0 p{
        display: none;
    }
    
    .lista-config .modal .card-info-config .card-footer .btn{
        width: 40%;
        margin-left: 60%;
    }

    .lista-config.proveedores .col-0,
    .lista-config.proveedores .col-0 p{
        display: none;
    }

    .config .lista-config.proveedores .titulo p{
        font-size: 0.8rem;
    }
}
